import createElementFromHTML from 'plugins/element/create_element_from_html';
import isElement from 'plugins/element/is_element';
import remove from 'plugins/element/remove';

function replaceWith(element, newContent) {
  const parentElement = element.parentNode;
  const newElement = isElement(newContent) ? newContent : createElementFromHTML(newContent);

  if (newElement instanceof HTMLCollection) {
    while (newElement.length > 0) {
      element.insertAdjacentElement('beforebegin', newElement.item(0));
    }

    remove(element);
  } else {
    parentElement.replaceChild(newElement, element);
  }

  return newElement;
}

export default replaceWith;
